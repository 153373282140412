<template>
  <div
    v-if="images?.length"
    class="gallery-grid-container paragraph flex flex-wrap gap-[15px] md:gap-24"
    :class="{
      'right-aligned': rightAligned,
      'left-aligned': !rightAligned,
    }"
  >
    <MediaImage
      v-for="(image, index) in images"
      :key="index"
      v-bind="image"
      class="order-3 inline-block"
      :image-style="imageStyle(index)"
      :class="{
        'gallery-item-normal': sizeSm(index) === 'normal',
        'gallery-item-full': sizeSm(index) === 'full',
        'md:gallery-item-small': sizeMd(index) === 'small',
        'md:gallery-item-big': sizeMd(index) === 'big',
        'md:gallery-item-normal': sizeMd(index) === 'normal',
        'md:gallery-item-big-right': galleryItemBigRight(index),
        'md:gallery-item-big-left': galleryItemBigLeft(index),
        '!order-1':
          index === 0 ||
          (index === 1 && images.length !== 2) ||
          (index === 2 && images?.length % 2 === 1),
        'md:order-1': index < 2,
      }"
    />
    <Text
      v-if="hasText"
      :class="{
        '!w-full': images?.length === 2 || images?.length === 4,
      }"
      class="gallery-grid-text-item md:gallery-item-big order-2 flex w-full flex-col justify-center py-24 text-base md:py-0"
    >
      <!-- we use percentage fontsizes here so the scaling from the Text element works proportionally -->
      <!-- text-4xl / text-base = 250%; line-height of text-4xl = 1.2 -->
      <!-- mobile text = 32 / 16 = 200%; line-height: 40 / 32 = 1.25 -->
      <div
        v-blokkli-editable:field_headline
        class="text-[200%] font-light uppercase [line-height:125%] md:text-[250%] md:[line-height:120%]"
      >
        {{ headline }}
      </div>
      <!-- text-2xl / text-base = 150%; line-height of text-2xl = 1.33 -->
      <!-- mobile text = 20 / 16 = 125%; line-height: 32 / 20 = 1.6 -->
      <div
        v-blokkli-editable:field_sub_headline
        class="my-16 text-[125%] font-light uppercase [line-height:160%] md:text-[150%] md:[line-height:133%]"
      >
        {{ subHeadline }}
      </div>
      <div
        v-blokkli-editable:field_description="{ type: 'frame' }"
        class="rich-text h-min font-light [line-height:150%]"
        v-html="description"
      ></div>
    </Text>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphGalleryGridFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'gallery_grid',
  options: {
    alignment: {
      type: 'radios',
      label: 'Alignment',
      default: 'left',
      options: {
        left: 'Left',
        right: 'Right',
      },
    },
  },
  editor: {
    determineVisibleOptions: (ctx) => {
      // The alignment option only affects the layout if there is an uneven
      // amount of images.
      const totalImages = ctx.props.images?.length || 0
      if (!totalImages) {
        return []
      }
      const hasImagesUneven = totalImages % 2 !== 0
      if (hasImagesUneven) {
        return ['alignment']
      }

      return []
    },
  },
})

const props = defineProps<{
  headline?: ParagraphGalleryGridFragment['headline']
  subHeadline?: ParagraphGalleryGridFragment['subHeadline']
  description?: ParagraphGalleryGridFragment['description']
  images?: ParagraphGalleryGridFragment['images']
}>()

const rightAligned = computed(() => options.value.alignment === 'right')
const hasText = computed(
  () => props.headline || props.subHeadline || props.description,
)

const imageStyle = (index: number) => {
  const aspectRatios = {
    small: 1 / 1,
    normal: 12.5 / 9,
    big: 16 / 9,
    full: 2 / 1, // not perfect but should work
  }

  let smStyle: { width: number; aspectRatio: number } = {
    width: 380,
    aspectRatio: aspectRatios.normal,
  } // case 'normal'

  if (sizeSm(index) === 'full') {
    smStyle = {
      width: 770,
      aspectRatio: aspectRatios.full,
    }
  }

  let mdStyle: { width: number; aspectRatio: number } = {
    width: 360,
    aspectRatio: aspectRatios.small,
  } // case small

  let lgStyle = {
    width: 425,
    aspectRatio: aspectRatios.small,
  } // case small

  let xlStyle = {
    width: 515,
    aspectRatio: aspectRatios.small,
  } // case small

  let xxlStyle = {
    width: 890,
    aspectRatio: aspectRatios.small,
  } // case small

  if (sizeMd(index) === 'normal') {
    mdStyle = {
      width: 500,
      aspectRatio: aspectRatios.normal,
    }
    lgStyle = {
      width: 590,
      aspectRatio: aspectRatios.normal,
    }
    xlStyle = {
      width: 715,
      aspectRatio: aspectRatios.normal,
    }
    xxlStyle = {
      width: 1240,
      aspectRatio: aspectRatios.normal,
    }
  }

  if (sizeMd(index) === 'big') {
    mdStyle = {
      width: 640,
      aspectRatio: aspectRatios.big,
    }
    lgStyle = {
      width: 750,
      aspectRatio: aspectRatios.big,
    }
    xlStyle = {
      width: 915,
      aspectRatio: aspectRatios.big,
    }
    xxlStyle = {
      width: 1590,
      aspectRatio: aspectRatios.big,
    }
  }

  return defineImageStyle({
    type: 'pictures',
    pictures: {
      sm: smStyle,
      md: mdStyle,
      lg: lgStyle,
      xl: xlStyle,
      xxl: xxlStyle,
    },
  })
}

const sizeSm = (index: number): 'normal' | 'full' => {
  if (
    index === 0 ||
    props.images?.length === 2 ||
    props.images?.length === 4 ||
    index === 5
  ) {
    return 'full'
  }

  return 'normal'
}

const galleryItemBigRight = (index: number) => {
  return !hasText.value && index === 2 && props.images?.length === 3
}

const galleryItemBigLeft = (index: number) => {
  return !hasText.value && index === 4 && props.images?.length === 5
}

const sizeMd = (index: number): 'normal' | 'small' | 'big' => {
  const length = props.images?.length ?? 0

  if (
    index === 5 ||
    (length === 6 && index === 4) ||
    (hasText.value &&
      ((index === 2 && length === 4) || (index >= 3 && length >= 4)))
  ) {
    return 'normal'
  } else if (
    index === 0 ||
    index === 3 ||
    galleryItemBigLeft(index) ||
    galleryItemBigRight(index)
  ) {
    return 'big'
  }

  return 'small'
}
</script>

<style lang="postcss" scoped>
:deep(img) {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.gallery-item-big {
  width: calc((100cqw - 15px) / 25 * 16);
  height: calc((100cqw - 15px) / 25 * 9);
}

.gallery-item-small {
  width: calc((100cqw - 15px) / 25 * 9);
  height: calc((100cqw - 15px) / 25 * 9);
}

.gallery-item-normal {
  width: calc((100cqw - 15px) / 2); /* / 25 * 12.5 */
  height: calc((100cqw - 15px) / 25 * 9);
}

.gallery-item-full {
  width: 100cqw;
  height: calc((100cqw - 15px) / 25 * 9);
}

@media (screen(md)) {
  .gallery-item-big {
    width: calc((100cqw - 24px) / 25 * 16);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .gallery-item-normal {
    width: calc((100cqw - 24px) / 2);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .gallery-item-small {
    width: calc((100cqw - 24px) / 25 * 9);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .md\:gallery-item-big {
    width: calc((100cqw - 24px) / 25 * 16);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .md\:gallery-item-small {
    width: calc((100cqw - 24px) / 25 * 9);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .md\:gallery-item-normal {
    width: calc((100cqw - 24px) / 2);
    height: calc((100cqw - 24px) / 25 * 9);
  }
}

.gallery-grid-container {
  container-type: inline-size;

  &.left-aligned {
    flex-direction: row-reverse;
  }

  @media (screen(md)) {
    &.right-aligned .md\:gallery-item-big-left,
    &.left-aligned .md\:gallery-item-big-right {
      margin-right: calc((100cqw - 24px) / 25 * 9 + 24px);
    }

    &.right-aligned .md\:gallery-item-big-right,
    &.left-aligned .md\:gallery-item-big-left {
      margin-left: calc((100cqw - 24px) / 25 * 9 + 24px);
    }
  }
}

.gallery-grid-text-item {
  padding-left: min(5cqw, 80px);
  padding-right: min(5cqw, 80px);
}
</style>
